<template>
   <div class="auth-wrapper auth-v1 px-2">
	  <div class="auth-inner py-2">

		 <!-- Forgot Password v1 -->
		 <b-card class="mb-0">
			<b-link class="brand-logo">
			   <!-- logo -->
			   <vuexy-logo />
			</b-link>

			<b-card-title class="mb-1">
			   {{ $t('auth.lost-password') }}
			</b-card-title>
			<b-card-text class="mb-2">
			   {{ $t('auth.lost-password-info') }}
			</b-card-text>

			<!-- form -->
			<validation-observer ref="simpleRules">
			   <b-form
				   class="auth-forgot-password-form mt-2"
				   @submit.prevent="validationForm"
			   >
				  <!-- email -->
				  <b-form-group
					  label="Email"
					  label-for="forgot-password-email"
				  >
					 <validation-provider
						 #default="{ errors }"
						 name="Email"
						 rules="required|email"
					 >
						<b-form-input
							id="forgot-password-email"
							v-model="userEmail"
							:state="errors.length > 0 ? false:null"
							name="forgot-password-email"
							placeholder="john@example.com"
						/>
						<small v-if="errors[0]"
							   class="text-danger"> {{ $t(getError(errors[0])) }}</small>
					 </validation-provider>
				  </b-form-group>

				  <!-- submit button -->
				  <b-button
					  block
					  type="submit"
					  variant="primary"
				  >
					 {{ $t('auth.send-link') }}
				  </b-button>
			   </b-form>
			</validation-observer>

			<b-card-text class="text-center mt-2">
			   <b-link :to="{name:'auth-login'}">
				  <feather-icon icon="ChevronLeftIcon" />
				  {{ $t('auth.return-login') }}
			   </b-link>
			</b-card-text>

		 </b-card>
		 <!-- /Forgot Password v1 -->
	  </div>
   </div>
</template>

<script>
import {ValidationObserver, ValidationProvider} from 'vee-validate'
import VuexyLogo from '@core/layouts/components/Logo.vue'
import {BButton, BCard, BCardText, BCardTitle, BForm, BFormGroup, BFormInput, BLink,} from 'bootstrap-vue'
import {email, required} from '@validations'
import axiosIns from "@/libs/axios";
import ToastMixin from "@/Mixins/ToastMixin";
import ToastificationContent from "@core/components/toastification/ToastificationContent";

export default {
   components: {
	  VuexyLogo,
	  BCard,
	  BLink,
	  BCardText,
	  BCardTitle,
	  BFormGroup,
	  BFormInput,
	  BButton,
	  BForm,
	  ValidationProvider,
	  ValidationObserver,
   },

   mixins: [ToastMixin],
   data() {
	  return {
		 userEmail: '',
		 // validation
		 required,
		 email,
	  }
   },
   methods: {
	  getError(error) {
		 return `errors.${error.toLowerCase().replaceAll(' ', '-').replace('.', '')}`
	  },
	  validationForm() {
		 this.$refs.simpleRules.validate().then(success => {
			if (success) {
			   const response = axiosIns.post(`/password-reset`, {
				  email: this.userEmail
			   })

			   this.$router.replace('/login')
				   .then(() => {
					  this.$toast({
						 component: ToastificationContent,
						 position: 'top-right',
						 props: {
							title: `Το email στάλθηκε`,
							icon: 'CoffeeIcon',
							variant: 'success',
						 },
					  })
				   })


			}
		 })
	  },
   },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
</style>
